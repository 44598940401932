<!-- 后测数据筛选下载-创建任务 -->
<template>
  <el-card class="box-card">
    <el-steps :active="active" finish-status="success">
      <el-step title="输入任务详情" />
      <el-step title="输入任务名和任务类型" />
    </el-steps>
    <div class="mt-20" v-if="active === 0">
      <el-form ref="ruleFormRef" :model="screen" :rules="screenRules">
        <el-form-item prop="marketType">
          <template #label>
            <div class="label-width">
              <span class="colorRed">*</span>
              <span class="text">文章范围</span>
            </div>
          </template>
          <el-radio-group v-model="screen.articleScope">
            <el-radio :label="1">所有发文</el-radio>
            <el-radio :label="2">美妆发文</el-radio>
            <el-radio :label="3"
              >品类大盘
              <el-select
                style="width: 146px"
                v-model="screen.marketType"
                :disabled="screen.articleScope !== 3"
                placeholder="请选择品类大盘"
              >
                <el-option
                  v-for="item in categoryMarket"
                  :key="item"
                  :label="item"
                  :value="item"
                />
              </el-select>
            </el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item v-if="screen.articleScope === 2">
          <template #label>
            <div class="label-width">
              <span class="text">合作品类</span>
            </div>
          </template>
          <div class="unify">
            <el-cascader
              v-model="memory.category"
              :options="categoryList"
              :props="props"
              collapse-tags
              collapse-tags-tooltip
              @change="categoryChange"
              clearable
              style="width: 100%"
            />
          </div>
        </el-form-item>

        <el-form-item v-if="screen.articleScope === 2">
          <template #label>
            <div class="label-width">
              <span class="text">合作品牌</span>
            </div>
          </template>
          <div class="unify">
            <el-select
              :disabled="screen.articleScope !== 2"
              v-model="memory.brand"
              multiple
              filterable
              collapse-tags
              collapse-tags-tooltip
              placeholder="请选择品牌（支持多选可搜索）"
              style="width: 100%"
            >
              <el-option
                v-for="item in brandList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </div>
        </el-form-item>

        <el-form-item prop="timeRange">
          <template #label>
            <div class="label-width">
              <span class="colorRed">*</span>
              <span class="text">时间范围</span>
            </div>
          </template>
          <div class="unify">
            <el-radio v-model="timeType" :label="0">月</el-radio>
            <el-radio v-model="timeType" :label="1">日</el-radio>
            <el-date-picker
              v-model="timeRange"
              range-separator="To"
              popper-class="picker-down"
              :type="type"
              :unlink-panels="true"
              :start-placeholder="startPlaceholder"
              :end-placeholder="endPlaceholder"
              :shortcuts="shortcuts"
              :disabled-date="disabledDate"
              @calendar-change="pickerChange"
            />
          </div>
        </el-form-item>

        <el-form-item prop="platform">
          <template #label>
            <div class="label-width">
              <span class="colorRed">*</span>
              <span class="text">平台</span>
            </div>
          </template>
          <div class="unify">
            <el-select
              style="width: 100%"
              v-model="screen.platform"
              multiple
              placeholder="请选择"
            >
              <el-option
                v-for="item in tempSite"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </div>
        </el-form-item>

        <el-form-item prop="keywordFile">
          <template #label>
            <div class="label-width">
              <span class="text">关键词文件</span>
            </div>
          </template>
          <div class="unify exception">
            <el-radio-group v-model="screen.keywordFileType">
              <el-radio :label="1">搜索标题</el-radio>
              <el-radio :label="2">搜索标题+内容</el-radio>
            </el-radio-group>
            <div style="position: relative">
              <el-upload
                ref="upload"
                v-model:file-list="fileList"
                action="/"
                :limit="1"
                :headers="{ token }"
                :on-remove="handleRemove"
                :on-exceed="
                  (file) => {
                    handleExceed(file);
                  }
                "
                :http-request="
                  (file) => {
                    return uploadFile(file);
                  }
                "
              >
                <el-button type="primary">上传</el-button>
              </el-upload>
            </div>

            <div @click="downloadTemplate" class="button-text">
              下载上传模板
            </div>
          </div>
        </el-form-item>

        <el-form-item prop="mkolId">
          <template #label>
            <div class="label-width">
              <span class="text">KOL</span>
            </div>
          </template>
          <div class="unify">
            <el-input
              style="width: 100%"
              v-model="mkolId"
              placeholder="请输入KOL的m kolid,空格分隔"
            />
          </div>
        </el-form-item>

        <el-form-item>
          <template #label>
            <div class="label-width">
              <span class="text">KOL类型</span>
            </div>
          </template>
          <div class="unify">
            <el-cascader
              v-model="memory.kolType"
              :options="site"
              :props="props"
              collapse-tags
              collapse-tags-tooltip
              clearable
              filterable
              @change="mkolChange"
              placeholder="请选择KOL类型（支持多选可搜索）"
              style="width: 100%"
            />
          </div>
        </el-form-item>
      </el-form>
      <div class="card-footer">
        <el-button @click="resetForm(ruleFormRef)" class="mt-20"
          >重置</el-button
        >
        <el-button @click="submitForm(ruleFormRef)" type="primary"
          >下一步</el-button
        >
      </div>
    </div>
    <div class="mt-20" v-else>
      <el-form ref="ruleRef" :model="data" :rules="rules">
        <el-form-item prop="taskName">
          <template #label>
            <div class="label-width">
              <span class="colorRed">*</span>
              <span class="text">任务名称</span>
            </div>
          </template>
          <div class="unify">
            <el-input v-model="data.taskName" />
          </div>
        </el-form-item>
        <el-form-item prop="taskDesc">
          <template #label>
            <div class="label-width">
              <span class="text">任务说明</span>
            </div>
          </template>
          <div class="unify">
            <el-input v-model="data.taskDesc" />
          </div>
        </el-form-item>
        <el-form-item name="resultType">
          <template #label>
            <div class="label-width">
              <span class="text">下载类型</span>
            </div>
          </template>
          <div class="down-type">
            <el-radio-group v-model="data.resultType.type">
              <el-radio :label="1"> 全量发帖数据 </el-radio>
              <el-radio :label="2">
                <span class="m-right">TOP</span>
                <el-input-number
                  v-model="data.resultType.detail"
                  :min="1"
                  controls-position="right"
                />
                <span class="m-left">发帖数据</span>
              </el-radio>
              <el-radio v-if="screen.keywordFile.length" :label="3"
                >维度统计数据</el-radio
              >
              <el-radio v-if="screen.articleScope === 2" :label="4"
                >品牌人群画像</el-radio
              >
              <el-radio v-if="screen.articleScope === 2" :label="5"
                >品牌KOL投放结构</el-radio
              >
            </el-radio-group>
          </div>
        </el-form-item>
      </el-form>
      <div class="card-footer">
        <el-button class="mt-20" @click="prev(ruleRef)">上一步</el-button>
        <el-button type="primary" @click="confirm(ruleRef)">创建</el-button>
      </div>
    </div>
  </el-card>
</template>

<script setup>
import { ref, reactive, onMounted, computed, watch, toRaw } from 'vue'
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { sovFile } from "@/api/tool";
import { sovCreate, categoryMarketList } from "@/api/tool";
import { getBrand } from "@/api/sov/sovBrand";
import { getCateTree } from "@/api/campaignInsight";
import { categoryGrouping, mkolGrouping, brandDispose } from "../tools";
import { ElMessage } from "element-plus";

import moment from "moment";

const store = useStore();
const router = useRouter()

//Ref
const ruleRef = ref(null)
const upload = (null)

const categoryList = ref([]);
const ruleFormRef = ref();
const fileList = ref([]);
const site = ref([]);
const timeType = ref(0);
const active = ref(0)
const type = ref("daterange");
const startPlaceholder = ref("开始月份");
const endPlaceholder = ref("结束月份");
const brandList = ref([]);
const mkolId = ref("");
const timeRange = ref([]);
const categoryMarket = ref([])
// const pickerVisible = ref(false)  //时间范围展开
// const pickerSelect = ref(0) //选中的次数（分辨是否是start）
// const pickerMonthSelect = ref([])
const pickerDown = ref(null)
const selectDate = ref(null)
const isSelectDate = ref(false)


const screen = reactive({
  articleScope: 1,
  keywordFileType: 1,
  keywordFile: [],
  category: [],
  platform: [],
  brand: [],
  mkolId: [],
  mkolCategory: [],
  startDate: "",
  endDate: "",
  marketType: ''
});

const data = reactive({
  taskName: '',
  taskDesc: '',
  resultType: {
    type: 1,
    detail: 100
  }
})

//临时存储筛选中联级选择器内容
const memory = reactive({
  category: [],
  kolType: [],
  brand: [],
});

// const monthMap = {
//   '一月': '01',
//   '二月': '02',
//   '三月': '03',
//   '四月': '04',
//   '五月': '05',
//   '六月': '06',
//   '七月': '07',
//   '八月': '08',
//   '九月': '09',
//   '十月': '10',
//   '十一月': '11',
//   '十二月': '12'
// };

const shortcuts = ref([
  {
    text: "近1个月",
    value: () => setShortcuts(1),
  },
  {
    text: "近3个月",
    value: () => setShortcuts(3),
  },
  {
    text: "近6个月",
    value: () => setShortcuts(6),
  },
]);


const setShortcuts = (val) => {
  const start = new Date();
  start.setMonth(start.getMonth() - val);
  return [start, new Date()];
}

const v_marketType = (rule, value, callback) => {
  if (screen.articleScope === 3 && !screen.marketType) {
    callback(new Error("请选择品类大盘"));
  } else {
    callback();
  }
};


const v_mkolId = (rule, value, callback) => {
  const verify = /^[a-z0-9 ]*$/g;
  if (!verify.test(mkolId.value)) {
    callback(new Error("m kolid 只能是数字或小写字母"));
  } else {
    callback();
  }
};

const v_platform = (rule, value, callback) => {
  if (!value.length) {
    callback(new Error("请选择平台"));
  } else {
    callback();
  }
};
const v_timeRange = (rule, value, callback) => {
  if (timeRange.value.length < 2) {
    callback(new Error("请选择时间范围"));
  } else if (timeType.value === 0 && screen.articleScope === 2) {
    const date1 = new Date(timeRange.value[0]);
    const date2 = new Date(timeRange.value[1]);
    const monthDifference = (date2.getFullYear() - date1.getFullYear()) * 12 + date2.getMonth() - date1.getMonth();
    if (Math.abs(monthDifference) > 12) {
      callback(new Error("美妆发文时间范围不能超过12个月"));
    } else {
      callback();
    }
  } else if (timeType.value === 0 && screen.articleScope !== 2) {
    const date1 = new Date(timeRange.value[0]);
    const date2 = new Date(timeRange.value[1]);
    const monthDifference = (date2.getFullYear() - date1.getFullYear()) * 12 + date2.getMonth() - date1.getMonth();
    if (Math.abs(monthDifference) > 3) {
      callback(new Error("所有发文与品类大盘时间范围不能超过3个月"));
    } else {
      callback();
    }
  } else {
    callback();
  }
};

const v_keywordFile = (rule, value, callback) => {
  if (screen.articleScope === 1 && !screen.keywordFile.length) {
    callback(new Error("请上传关键词文件"));
  } else {
    callback();
  }
};

const v_taskName = (rule, value, callback) => {
  if (value === '') {
    callback(new Error('请输入任务名称'))
  } else {
    if (value.length > 20 || value.length < 2) {
      callback(new Error('任务名称在 2-20 字之间'))
    } else {
      callback()
    }
  }
}

const v_taskDesc = (rule, value, callback) => {
  if (value.length && (value.length < 6 || value.length > 120)) {
    callback(new Error('任务说明在 6-120 字之间'))
  } else {
    callback()
  }
}

const rules = reactive({
  taskName: [{ validator: v_taskName, trigger: 'change' }],
  taskDesc: [{ validator: v_taskDesc, trigger: 'change' }],
})


const screenRules = reactive({
  platform: [{ validator: v_platform, trigger: "change" }],
  timeRange: [{ validator: v_timeRange, trigger: "blur" }],
  mkolId: [{ validator: v_mkolId, trigger: "change" }],
  keywordFile: [{ validator: v_keywordFile, trigger: "blur" }],
  marketType: [{ validator: v_marketType }]
});

const token = computed(() => store.state.token);
const tempSite = computed(() => store.state.cascaderSite);

onMounted(() => {
  getCategoryMarketList()
  getCateTreeData()
  getBrandData();
})


/**
 * 获取品牌大盘select内容
 */
const getCategoryMarketList = async () => {
  const { data } = await categoryMarketList()
  categoryMarket.value = data
}

const getCateTreeData = async () => {
  const { data } = await getCateTree();
  categoryList.value = data.map(({ tagId, tagName, children }) => {
    let newChildren = [];
    if (children) {
      newChildren = children.map(({ tagId, tagName, children }) => {
        return {
          label: tagName,
          value: tagId,
          children,
        };
      });
    }
    return {
      label: tagName,
      value: tagId,
      children: newChildren,
    };
  });
};

const getBrandData = async () => {
  const { data } = await getBrand();
  brandList.value = data.map((item) => {
    return {
      label: item.brand,
      value: item.brandId,
    };
  });
};

//重置筛选
const resetForm = (formEl) => {
  formEl.resetFields();
  screen.articleScope = 1;
  screen.platform = [];
  screen.keywordFile = [];
  screen.keywordFileType = 1;
  screen.mkolCategory = [];
  timeRange.value = [];
  timeType.value = 0;
  mkolId.value = "";
  fileList.value = [];
  memory.kolType = [];
  screen.platform = tempSite.value.map((item) => item.value);
  screen.marketType = ''
};

/**
 * 上传与下载
 */
const uploadFile = async (params) => {
  screen.keywordFile = [];
  const { code, data } = await sovFile(params.file);
  if (!data.length) {
    upload.value.clearFiles();
    fileList.value = [];
    return ElMessage.error("上传文件不正确，请重新上传!");
  }

  if (code) {
    upload.value.clearFiles();
    fileList.value = [];
    return ElMessage.error("上传文件失败，请重新上传!");
  }

  screen.keywordFile = data;
  ElMessage.success("上传文件成功!");
  setTimeout(() => {
    ruleFormRef.value.clearValidate(["keywordFile"]);
  }, 0);
};

const handleExceed = (files) => {
  uploadFile({ file: files[0] });
};

const handleRemove = (file, uploadFiles) => {
  fileList.value = uploadFiles;
  screen.keywordFile = [];
};

const submitForm = (formEl) => {
  if (!formEl) return;
  formEl.validate((valid) => {
    if (valid) {
      active.value = 1
    } else {
      console.log("error submit!");
      return false;
    }
  });
};

const downloadTemplate = () => {
  window.location.href = "https://omnivoice-kol.oss-cn-hangzhou.aliyuncs.com/omnivoice/template-files/%E5%85%B3%E9%94%AE%E8%AF%8D%E6%96%87%E4%BB%B6%E4%B8%8A%E4%BC%A0%E6%A8%A1%E7%89%88.xlsx"
}

const confirm = (formEl) => {
  if (!formEl) return
  formEl.validate((valid) => {
    if (valid) {
      dialogConfirm()
    } else {
      console.log('error submit!')
      return false
    }
  })
}

const dialogConfirm = () => {
  if (data.resultType.type !== 2) {
    data.resultType.detail = null;
  }
  screen.startDate =
    timeType.value === 0
      ? moment(timeRange.value[0]).startOf("month").format("YYYY-MM-DD")
      : moment(timeRange.value[0]).format("yyyy-MM-DD");
  screen.endDate =
    timeType.value === 0
      ? moment(timeRange.value[1]).endOf("month").format("YYYY-MM-DD")
      : moment(timeRange.value[1]).format("yyyy-MM-DD");

  screen.mkolCategory = [...mkolGrouping(memory.kolType, tempSite.value)];
  screen.mkolId = mkolId.value ? mkolId.value.split(" ") : [];
  screen.brand = [...brandDispose(memory.brand, brandList.value)];
  screen.category = [...categoryGrouping(memory.category, categoryList.value)];

  createRequest({
    ...screen,
    ...toRaw(data),
  });

  ruleRef.value.resetFields()
  data.resultType.type = 1
  data.resultType.detail = 100
  setTimeout(() => {
    ruleRef.value.clearValidate(['taskName', 'taskDesc'])
  }, 0)
  screen.mkolCategory = [];
  router.push({
    name: 'HcDataDownload'
  })
};

const createRequest = async (params) => {
  const data = await sovCreate(params);
  data.code === 0 ? ElMessage.success("创建成功") : ElMessage.error("创建失败");
};

//上一步
const prev = (formEl) => {
  active.value = 0
  formEl?.resetFields()
  data.resultType.type = 1
  data.resultType.detail = 100
}

const pickerChange = (val) => {
  selectDate.value = val[0]
  isSelectDate.value = val[1] ? false : true
}

// /**
//  * 给时间范围翻页按钮绑定事件
//  */
// const pickerPageTurning = () => {
//   pickerTDItem()
// }

// /**
//  * 时间范围(月)选中
//  */
// const pickerTDLeft = (v) => {
//   pickerSelect.value++
//   const leftYear = pickerDown.value.querySelector('.is-left .el-date-range-picker__header div')
//   const year = leftYear.innerText.split(' ')[0]
//   const month = v.target.innerText
//   if (pickerSelect.value % 2 !== 0) {
//     selectDate.value = year + monthMap[month]
//     isSelectDate.value = true
//   } else {
//     isSelectDate.value = false
//   }

//   disabledDate()
// }

// const pickerTDRight = (v) => {
//   pickerSelect.value++
//   const rightYear = pickerDown.value.querySelector('.is-right .el-date-range-picker__header div')
//   const year = rightYear.innerText.split(' ')[0]
//   const month = v.target.innerText
//   if (pickerSelect.value % 2 !== 0) {
//     selectDate.value = year + monthMap[month]
//     isSelectDate.value = true
//   } else {
//     isSelectDate.value = false
//   }
//   disabledDate()
// }

// /**
//  * 给时间范围(月)每一项绑定事件
//  */
// const pickerTDItem = () => {
//   const leftTDList = pickerDown.value.querySelectorAll('.is-left td')
//   const rightTDList = pickerDown.value.querySelectorAll('.is-right td')
//   leftTDList.forEach((item) => {
//     item.removeEventListener('click', pickerTDLeft)
//     item.addEventListener('click', pickerTDLeft)
//   })
//   rightTDList.forEach((item) => {
//     item.removeEventListener('click', pickerTDRight)
//     item.addEventListener('click', pickerTDRight)
//   })
// }



// const pickerFocus = () => {
//   if (timeType.value === 0) {
//     pickerVisible.value = true
//     const down = document.querySelector('.picker-down')
//     const btn = down.querySelectorAll('.el-date-range-picker__header button')
//     pickerDown.value = down
//     pickerTDItem()
//     btn.forEach((item) => {
//       item.removeEventListener('click', pickerPageTurning)
//       item.addEventListener('click', pickerPageTurning)
//     })
//   }
// }

// const pickerBlur = () => {
//   pickerVisible.value = false
//   pickerSelect.value = 0
// }


/**
 * 设置时间范围的日期禁用
 */
const disabledDate = (time) => {
  if (selectDate.value) {
    const start = new Date(selectDate.value);
    start.setMonth(start.getMonth() - (screen.articleScope === 2 ? 12 : 3));
    const end = new Date(selectDate.value);
    end.setMonth(end.getMonth() + (screen.articleScope === 2 ? 12 : 3));
    return time.getTime() < start.getTime() || time.getTime() > end.getTime();
  }
}


watch(
  () => timeType.value,
  (val) => {
    if (val === 0) {
      type.value = "monthrange";
      startPlaceholder.value = "开始月份";
      endPlaceholder.value = "结束月份";
    } else {
      type.value = "daterange";
      startPlaceholder.value = "开始日期";
      endPlaceholder.value = "结束日期";
    }
  },
  { immediate: true }
);


watch(
  () => tempSite.value,
  (val) => {
    if (val) {
      site.value = JSON.parse(JSON.stringify(val));
      screen.platform = val.map((item) => item.value);

    }
  }
);

watch(
  () => screen.platform,
  (val, old) => {
    site.value = JSON.parse(JSON.stringify(tempSite.value));
    if (val) {
      for (const key of val) {
        const index = site.value.findIndex((item) => item.value === key);
        if (index >= 0) site.value[index].disabled = false;
      }
    }
    if (old.length > val.length) {
      if (memory.kolType.length) {
        const value = old.concat(val).filter((item) => {
          return !(old.includes(item) && val.includes(item));
        });
        memory.kolType = memory.kolType.filter((item) => {
          return item[0] !== value[0];
        });
      }
    }
  }
);

watch(
  () => screen.articleScope,
  (val) => {
    if (val !== 2 && JSON.stringify(data) !== "{}") {
      memory.category = [];
      memory.brand = [];
      if (data.resultType.type >= 3) {
        data.resultType.type = 0;
      }
    }
    if (val !== 3) {
      screen.marketType = ''
    }

    console.log(shortcuts.value, '  shortcuts.value')
  },
  { immediate: true, deep: true }
);


</script>

<style lang="scss" scoped>
.el-card {
  width: 100%;
  height: 100%;
}

.box-card {
  border-radius: 5px;
}

:deep(.el-steps) {
  width: 60%;

  .is-process {
    .el-step__icon.is-text {
      border-color: #4d7ad7 !important;
      background: #4d7ad7;

      .el-step__icon-inner {
        color: #fff;
      }
    }
  }
}

.label-width {
  padding-left: 12px;
  width: 125px;
  text-align: left;
  position: relative;

  .colorRed {
    position: absolute;
    left: 0;
    color: #ff0000;
  }

  .text {
    font-size: 16px;
    font-weight: 700;
    color: #777777;
  }
}

.unify {
  width: 445px;
  height: 32px;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .button-text {
    margin-left: 12px;
    color: #409eff;
    cursor: pointer;
  }

  .button-text:hover {
    color: #a0cfff;
  }
}

.unify.exception {
  label.el-radio {
    margin-right: 14px;
  }
}

.card-footer {
  margin-top: 120px;
  padding: 0 20px;
  display: flex;
  justify-content: center;
}

.el-button--primary {
  height: 30px;
  background: linear-gradient(180deg, #4d7ad7 0%, #5e8cec 100%);
}

.el-form-item {
  min-height: 34px;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 18px;
}

:deep(.el-upload-list.el-upload-list--text) {
  position: absolute;
  bottom: -15px;
  left: -270px;
}

:deep(.el-form-item__error) {
  padding-top: 0;
}

:deep(.el-radio-button__original-radio:checked + .el-radio-button__inner),
:deep(.el-radio-button__orig-radio:checked + .el-radio-button__inner) {
  background: linear-gradient(180deg, #daae3c 0%, #e09524 100%);
  border-image: linear-gradient(180deg, #daae3c 0%, #e09524 100%);
  border-color: transparent;
  box-shadow: -1px 0 0 0 transparent;
}

:deep(.el-radio__input.is-checked) {
  .el-radio__inner {
    color: #e09a29 !important;
    border-color: #e09a29 !important;
    background: linear-gradient(180deg, #daae3c, #e09524);
  }
}

:deep(.el-radio__input.is-checked + .el-radio__label) {
  color: #e09a29 !important;
}

:deep(.el-radio__inner:hover) {
  border-color: #e09a29;
}

:deep(.el-date-editor) {
  .el-range-separator {
    width: 8%;
  }
}

.down-type {
  padding-top: 0;
  :deep(.el-radio-group) {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;

    .el-radio {
      margin: 0;
      width: 220px;
      display: flex;
      justify-content: flex-start;
    }
  }

  .m-right {
    margin-right: 6px;
  }

  .m-left {
    margin-left: 6px;
  }

  .el-radio {
    display: block;
  }
}

:deep(.el-upload-list.el-upload-list--text) {
  bottom: -25px;
}
:deep(.el-radio__input) {
  // width: 300px;
}
</style>
