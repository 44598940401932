import request, { downloadFile } from "@/utils/axios";

export function getBrandList(params) {
  return request.get("/sov/brand/rankinglistV2", params);
}

export function getBrand(params) {
  return request.get("/sov/brand/list", params);
}

export function getCategory(params) {
  return request.get("/sov/product/cate/tree", params);
}

export function getBrandComparisonOverview(params) {
  return request.get("/sov/brand/comparison/overview", params);
}

export function getBrandComparisonDistribution(params) {
  return request.get("/sov/brand/comparison/ad/structure", params);
}

export function getBrandComparisonTrend(params) {
  return request.get("/sov/brand/trend", params);
}

export function getBrandArticleTop(params) {
  return request.get("/sov/brand/article", params);
}

export function getBrandArticleTopReport(params) {
  return request.get("/sov/brand/article/top/report", params);
}

export function getBrandFlow(params) {
  return request.get("/sov/brand/flow", params);
}

export function getBrandOverview(params) {
  return request.get("/sov/brand/overview", params);
}

export function getBrandOverviewReport(params) {
  return request.get("/sov/brand/overview/report", params);
}

export function getBrandDistribution(params) {
  return request.get("/sov/brand/distribution", params);
}

export function getBrandCateTrend(params) {
  return request.get("/sov/brand/cate/trend", params);
}

export function getBrandProductTrend(params) {
  return request.get("/sov/brand/product/trend", params);
}

export function getBrandMonth(params) {
  return request.get("/sov/brand/month", params);
}

export function downloadGeneralView(params, fileName) {
  return downloadFile("/sov/brand/report", params, fileName);
}

// 品牌kol投放结构 /sov/brand/ad/structure
export function getBrandAdStructure(params) {
  return request.get("/sov/brand/ad/structure", params);
}

// 人群画像
export function getCampaignInsightProfile(params) {
  return request.get("/sov/brand/persona", params);
}

export function downloadTopReport(params, fileName) {
  return downloadFile("/sov/brand/article/report", params, fileName);
}

// /sov/brand/comparison/persona
export function getSovBrandCommparisonPersona(params) {
  return request.get("/sov/brand/comparison/persona", params);
}

// /sov/brand/platform/ta  单品牌 投放TA触达效率
export function getSovBrandPlatformTa(params) {
  return request.get("/sov/brand/platform/ta", params);
}

// /sov/brand/comparison/platform/ta 多品牌 投放TA触达效率
export function getSovBrandComparisonPlatformTa(params) {
  return request.get("/sov/brand/comparison/platform/ta", params);
}

// 品牌榜单下载 /sov/brand/rankinglist/report
export function downLoadSovBrandRankingListReport(params, fileName) {
  return downloadFile("/sov/brand/rankinglist/report", params, fileName);
}

// 获取可选择月份 /sov/product/aggMonth
export function getSovProductAggMonth() {
  return request.get("/sov/brand/aggMonth");
}
