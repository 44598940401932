import request, { downloadFile } from "@/utils/axios";

export function getGroup(params) {
  return request.get("/campaign/insight/group", params);
}

export function getCateTree(params) {
  return request.get("/sov/product/cate/tree", params);
}

export function getCampaignInsightList(params) {
  return request.get("/campaign/insight", params);
}

export function getCampaignInsightOverview(params) {
  return request.get("/campaign/insight/overview", params);
}

export function getCampaignInsightDistribution(params) {
  return request.get("/campaign/insight/distribution", params);
}

export function getCampaignInsightTrend(params) {
  return request.get("/campaign/insight/trend", params);
}

export function getCampaignInsightProfile(params) {
  return request.get("/campaign/insight/profile", params);
}

export function getCampaignInsightContent(params) {
  return request.get("/campaign/insight/content", params);
}

export function getCampaignInsightArticleTop(params) {
  return request.get("/campaign/insight/article/top", params);
}

export function downloadArticle(id) {
  return downloadFile("/campaign/insight/article", { id }, "KOL投放内容.xlsx");
}

// campaign comparison
export function getComparisonOverview(params) {
  return request.get("/campaign/insight/comparison/overview", params);
}

export function getComparisonPlatform(params) {
  return request.get("/campaign/insight/comparison/platform", params);
}

export function getComparisonTier(params) {
  return request.get("/campaign/insight/comparison/tier", params);
}

export function getComparisonContent(params) {
  return request.get("/campaign/insight/comparison/content", params);
}

// comparison calendar
export function getComparisonCalendar(params) {
  return request.get("/campaign/insight/comparison/calendar", params);
}
