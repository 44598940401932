import moment from "moment";
export function setEndDate(time) {
  let nowTime = moment().format("yyyy-MM-DD")
  let choseTime = moment(time).format("yyyy-MM-DD");
  if (nowTime.split("-")[1] === choseTime.split("-")[1]) {
    return nowTime
  } else {
    return moment(time).endOf("month").format("yyyy-MM-DD")
  }
}

export function setStartDate(time) {
  return moment(time).startOf("month").format("yyyy-MM-DD");
}


//处理级联选择器的值
export function mkolGrouping(params, entire) {
  const parcel = []
  const result = []
  for (let i in params) {
    if (parcel.indexOf(params[i][0]) === -1) {
      parcel.push(params[i][0])
    }
  }
  for (let i = 0; i < parcel.length; i++) {
    const source = entire.filter(item => item.value === parcel[i])[0]
    const info = {
      label: source.label,
      value: parcel[i],
      category: []
    }
    for (let j = 0; j < params.length; j++) {
      if (params[j][0] === parcel[i]) {
        const child = source.children.filter(item => item.value === params[j][1])[0]
        info.category.push(child.label)
      }
    }
    result.push(info)
  }
  return result
}

export function categoryGrouping(params, entire) {
  const parcel = []
  const result = []
  for (let i in params) {
    if (parcel.indexOf(params[i][0]) === -1) {
      parcel.push(params[i][0])
    }
  }
  for (let i = 0; i < parcel.length; i++) {
    const source = entire.filter(item => item.value === parcel[i])[0]
    const info = {
      tagId: source.value,
      tagName: source.label,
      children: []
    }
    for (let j = 0; j < params.length; j++) {
      if (params[j][0] === parcel[i]) {
        const child = source.children.filter(item => item.value === params[j][1])[0]
        info.children.push({
          tagId: child.value,
          tagName: child.label,
          children: null
        })
      }
    }
    result.push(info)
  }
  return result
}

//处理合作品牌的值
export function brandDispose(params, entire) {
  const result = []
  for (let i = 0; i < params.length; i++) {
    const info = entire.filter(item => item.value === params[i])[0]
    result.push(
      {
        brandId:info.value,
        brand:info.label
      }
    )
  }
  return result
}
