import request, {
  uploadFile,
  downloadFile,
  applicationJson,
  downloadLocalFile,
} from "@/utils/axios";

// /tool/sov/article

export function downloadSovArticle(params, fileName) {
  return downloadFile("/tool/sov/article", params, fileName, {
    method: "post",
    headers: {
      "Content-Type": "application/json;charset=utf-8",
    },
  });
}

// /tool/sov/persona
export function downloadSovPersona(params, fileName) {
  return downloadFile("/tool/sov/persona", params, fileName, {
    method: "post",
    headers: {
      "Content-Type": "application/json;charset=utf-8",
    },
  });
}

// /tool/sov/kol
export function downloadSovKol(params, fileName) {
  return downloadFile("/tool/sov/kol", params, fileName, {
    method: "post",
    headers: {
      "Content-Type": "application/json;charset=utf-8",
    },
  });
}

// 获取任务列表
export function sovList(params) {
  return request.get("/tool/sov/list", params);
}

//创建任务申请
export function sovCreate(params) {
  return applicationJson.post("/tool/sov/create", params);
}

//获取品类大盘select内容
export function categoryMarketList() {
  return request.get("/tool/sov/getCategoryMarketList");
}

//上传关键词文件
export function sovFile(params) {
  return uploadFile("/tool/sov/file", params);
}

// 下载模板
export function downloadTemplate() {
  return downloadLocalFile(
    "./关键词文件上传模版.xlsx",
    "关键词文件上传模版.xlsx"
  );
}

//下载结果文件
export function sovResult(params) {
  return request.get("/tool/sov/result", params, {
    responseType: "arraybuffer",
  });
}

//删除结果
export function sovTask(params) {
  return request.delete("/tool/sov/task", params);
}


export function resetTask(params) {
  return request.put("/tool/sov/task", params);
}
